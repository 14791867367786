import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './Assets/Fonts/Poppins-Regular.ttf';
import './Assets/Fonts/Poppins-Light.ttf';
import './Assets/Fonts/Poppins-SemiBold.ttf';
import './Assets/Fonts/Poppins-ExtraBold.ttf';
import React from 'react';
import ReactDOM from 'react-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router} from 'react-router-dom';
ReactDOM.render(
  <Router>
    <App/>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
