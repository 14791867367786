import React from "react";
import { Link } from "react-router-dom";
import "./style.css";


export default function Footer({ footerData }) {
  return (
    <div className="footer-content">
      <div className="container">
        <div className="footer-row-one row g-0">
          <div className="question-copyright-div col-lg-5 col-sm-12">
            <div className="footer-contact-info">
              <p className="question-text">{footerData.text} <span style={{ whiteSpace: "nowrap" }}><a href="tel:+1-800-736-0003">1-800-736-0003</a></span></p>
              {footerData.body.map((item, index) => (
                <p key={index} className="question-body">{item}</p>
              ))}
            </div>
          </div>
          <div className="medicines-list col-lg-7 col-sm-12">
            {footerData.medicineList.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </div>
        <div className="copy-right-privacy row g-0">
          <div className="copy-right-box col-lg-5 col-sm-12">
            <p className="copy-right">{footerData.copyright}</p>
          </div>
          <div className="page-route-options col-lg-7 col-md-12">
            <ul className="page-route list-group list-group-horizontal d-flex">
              <li className="version-number list-group-item flex-grow-1">{footerData.version}</li>
              <li className="legal-notice list-group-item"><Link to={footerData.legalNotice.link}>{footerData.legalNotice.label}</Link></li>
              <li className="privacy-policy list-group-item"><Link to={footerData.privacyPolicy.link}>{footerData.privacyPolicy.label}</Link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

//export default Footer;
