import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';

// english documents
import NEW_PrimaryEnrollmentApplication from '../../Documents/English/BMSPAF-Application.pdf';
import NEW_CamzyosEnrollmentForm from '../../Documents/English/BMSPAF-Camzyos-Application.pdf';
import NEW_IntegrationEnrollmentApplication from '../../Documents/English/Integration_Application.pdf';
import NEW_OncologyApplication from '../../Documents/English/OncologyApplication.pdf';
import NEW_ZeposiaApplication from '../../Documents/English/ZeposiaApplication.pdf';
import RENEWAL_OncologyHematologyApplication from '../../Documents/English/Renew_OncologyHematologyRenewalApplication.pdf';
import RENEWAL_RemsApplication from '../../Documents/English/Renew_REMS-RenewalApplication.pdf';
import RENEWAL_PrimaryEnrollmentApplication from '../../Documents/English/Renew_BMSPAF-Application.pdf';
import RENEWAL_Zeposia_Application from '../../Documents/English/Renew_ZeposiaApplication.pdf';
import RENEWAL_CamzyosEnrollmentForm from '../../Documents/English/Renew_BMSPAF-Camzyos-Application.pdf';

//spanish documents
import NEW_PrimaryEnrollmentApplication_Spanish from '../../Documents/Spanish/BMSPAF-Application.pdf';
import NEW_CamzyosEnrollmentForm_Spanish from '../../Documents/Spanish/BMSPAF-Camzyos-Application.pdf';
import NEW_IntegrationEnrollmentApplication_Spanish from '../../Documents/Spanish/Integration_Application.pdf';
import NEW_OncologyApplication_Spanish from '../../Documents/Spanish/OncologyApplication.pdf';
import NEW_ZeposiaApplication_Spanish from '../../Documents/Spanish/ZeposiaApplication.pdf';
import RENEWAL_OncologyHematologyApplication_Spanish from '../../Documents/Spanish/Renew_OncologyHematologyRenewalApplication.pdf';
import RENEWAL_RemsApplication_Spanish from '../../Documents/Spanish/Renew_REMS-RenewalApplication.pdf';
import RENEWAL_PrimaryEnrollmentApplication_Spanish from '../../Documents/Spanish/Renew_BMSPAF-Application.pdf';
import RENEWAL_Zeposia_Application_Spanish from '../../Documents/Spanish/Renew_ZeposiaApplication.pdf';
import RENEWAL_CamzyosEnrollmentForm_Spanish from '../../Documents/Spanish/Renew_BMSPAF-Camzyos-Application.pdf';

function DownloadForm({
   lang,
   selectedMedicine,
   showDialog,
   updateShowDialog,
   dialogData,
}) {
   const [userType, setType] = useState();

   const hideDialog = () => {
      updateShowDialog(false);
      setType();
   };

   const openNewUserDocument = () => {
      // constants
      const hematology = [
         'ABRAXANE&REG;',
         'IDHIFA&REG;',
         'INREBIC&REG;',
         'ISTODAX&REG;',
         'ONUREG&REG;',
         'POMALYST&REG;',
         'REBLOZYL&REG;',
         'REVLIMID&REG;',
         'THALOMID&REG;',
         'VIDAZA&REG;',
      ];
      const oncology = [
         'Opdivo&reg;',
         'Yervoy&reg;',
         'Empliciti&reg;',
         'Sprycel&reg;',
         'Augtyro&trade'
      ];
      let medicine = selectedMedicine.medicineDetails;
      let url = ' ';
      if (medicine.isOncologyDrug) {
         if (hematology.indexOf(medicine.tradeName.toUpperCase()) > -1) {
            url =
               lang === 'english'
                  ? NEW_IntegrationEnrollmentApplication
                  : NEW_IntegrationEnrollmentApplication_Spanish;
         } else {
            url =
               lang === 'english'
                  ? NEW_OncologyApplication
                  : NEW_OncologyApplication_Spanish;
         }
      } else if (medicine.tradeName === 'ZEPOSIA&reg;') {
         url =
            lang === 'english'
               ? NEW_ZeposiaApplication
               : NEW_ZeposiaApplication_Spanish;
      } else if (medicine.tradeName === 'CAMZYOS&REG;') {
         url =
            lang === 'english'
               ? NEW_CamzyosEnrollmentForm
               : NEW_CamzyosEnrollmentForm_Spanish;
      } else {
         url =
            lang === 'english'
               ? NEW_PrimaryEnrollmentApplication
               : NEW_PrimaryEnrollmentApplication_Spanish;
      }
      // console.log('medicine', medicine, url)

      // console.log(url, "====>url");
      hideDialog();
      window.open(url, '_blank');
   };

   const openExistingUserDocument = () => {
      const CATEGORY1 = [
         'ABRAXANE&REG;',
         'AUGTYRO&TRADE;',
         'EMPLICITI&REG;',
         'IDHIFA&REG;',
         'INREBIC&REG;',
         'ISTODAX&REG;',
         'ONUREG&REG;',
         'OPDIVO&REG;',
         'OPDUALAG&TRADE;',
         'REBLOZYL&REG;',
         'SPRYCEL&REG;',
         'VIDAZA&REG;',
         'YERVOY&REG;'
      ];
      const CATEGORY2 = ['POMALYST&REG;', 'REVLIMID&REG;', 'THALOMID&REG;'];
      const CATEGORY3 = [
         'ELIQUIS&REG;',
         'NULOJIX&REG;',
         'ORENCIA&REG;',
         'SOTYKTU&REG;',
      ];
      const CATEGORY4 = ['ZEPOSIA&REG;'];
      const CATEGORY5 = ['CAMZYOS&REG;']; // ONLY CAMZYOS

      let medicine = selectedMedicine.medicineDetails;
      let url = '';

      if (CATEGORY1.indexOf(medicine.tradeName.toUpperCase()) > -1) {
         url =
            lang === 'english'
               ? RENEWAL_OncologyHematologyApplication
               : RENEWAL_OncologyHematologyApplication_Spanish;
      } else if (CATEGORY2.indexOf(medicine.tradeName.toUpperCase()) > -1) {
         url =
            lang === 'english'
               ? RENEWAL_RemsApplication
               : RENEWAL_RemsApplication_Spanish;
      } else if (CATEGORY3.indexOf(medicine.tradeName.toUpperCase()) > -1) {
         url =
            lang === 'english'
               ? RENEWAL_PrimaryEnrollmentApplication
               : RENEWAL_PrimaryEnrollmentApplication_Spanish;
      } else if (CATEGORY4.indexOf(medicine.tradeName.toUpperCase()) > -1) {
         url =
            lang === 'english'
               ? RENEWAL_Zeposia_Application
               : RENEWAL_Zeposia_Application_Spanish;
      } else if (CATEGORY5.indexOf(medicine.tradeName.toUpperCase()) > -1) {
         url =
            lang === 'english'
               ? RENEWAL_CamzyosEnrollmentForm
               : RENEWAL_CamzyosEnrollmentForm_Spanish;
      }

      // console.log(url, "====>url");
      hideDialog();
      window.open(url, '_blank');
   };

   const checkRenewInterrupter = () => {
      const medicines = [
         // 'ABRAXANE&REG;',
         // 'EMPLICITI&REG;',
         // 'IDHIFA&REG;',
         // 'INREBIC&REG;',
         // 'ISTODAX&REG;',
         // 'ONUREG&REG;',
         // 'OPDIVO&REG;',
         // 'OPDUALAG&TRADE;',
         // 'POMALYST&REG;',
         // 'REBLOZYL&REG;',
         // 'REVLIMID&REG;',
         // 'SPRYCEL&REG;',
         // 'THALOMID&REG;',
         // 'VIDAZA&REG;',
         // 'YERVOY&REG;',
         // 'AUGTYRO&TRADE;'
      ];
      let medicine = selectedMedicine?.medicineDetails;
      if (medicines.indexOf(medicine?.tradeName.toUpperCase()) > -1) {
         return true;
      }
      return false;
   }

   const openDocument = () => {
      if (userType === 'new') {
         openNewUserDocument();
      } else {
         openExistingUserDocument();
      }
   };

   return (
      <Dialog
         visible={showDialog}
         onHide={() => hideDialog()}
         className="download-form"
         header="">
         {/* buttons only view */}
         {/* <div className="btn-flex-group">
        <Button
          label="New Customer"
          className="p-button-outlined p-button-help"
          onClick={() => openNewUserDocument()}
        />
        <Button
          label="Existing Customer"
          className="p-button-outlined p-button-help"
          onClick={() => openExistingUserDocument()}
        />
      </div> */}

         {/* Radio Button view */}
         <div className="radio-group" aria-activedescendant="userType1" tabIndex="0">
            {dialogData.radioBtns.map((item, index) => (
               <div className="radio-subgroup" >
                  <h4>{item.title}</h4>
                  {(index === 1 && checkRenewInterrupter()) ?
                     <div className="field-text">
                        <label
                           style={{ marginLeft: 10 }}
                           htmlFor="userType1"
                           dangerouslySetInnerHTML={{
                              __html: dialogData.renewInterrupter_msg,
                           }}></label>
                     </div>
                     :
                     <div className="field-radiobutton">
                        <RadioButton
                           role="radio"
                           aria-checked={userType === item.userType}
                           inputId={"userType" + index}
                           name="userType"
                           value={item.userType}
                           onChange={(e) => setType(e.value)}
                           checked={userType === item.userType}
                        />
                        <label
                           style={{ marginLeft: 10 }}
                           htmlFor={"userType" + index}
                           dangerouslySetInnerHTML={{
                              __html: item.label,
                           }}></label>
                     </div>
                  }
               </div>
            ))}
         </div>
         <div className="btn-group">
            {/* <Button
          label="Cancel"
          className="p-button-outlined p-button-help btn-secondary"
          onClick={() => hideDialog()}
        /> */}
            <Button
               label={dialogData.submitLabel}
               className="p-button-outlined p-button-help btn-primary"
               onClick={() => openDocument()}
               disabled={!userType}
            />
         </div>
      </Dialog>
   );
}

export default DownloadForm;
